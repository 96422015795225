.slide-container {
    width: 100%;
    margin: auto; }

h3 {
    text-align: center; }

.each-slide > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 350px;
}

.each-slide span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
}

.each-fade {
    display: flex;
}

.each-fade .image-container {
    width: 100%;
    overflow: hidden;
}

.each-fade .image-container img {
    width: 100px;
    height: 486px !important;
}

/*.each-fade h2 {*/
    /*width: 25%;*/
    /*display: flex;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    /*margin: 0;*/
    /*background: #adceed;*/
/*}*/