/* * {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
} */

.h2custom {
  text-transform: uppercase;
  font-size: 15px;
  text-align: center;
  margin-top: 3%;
}

.animateText {
  animation: slideInLeft 1s ease-out;
}

.pincode-input {
  width: 70px;
  height: 70px;
  border-radius: 5px;
  font-size: 45px;
  text-align: center;
  margin: 10px;
  font-family: monospace;
  text-transform: uppercase;
}

.phone-resetcustom {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5%;
  padding: 10px;
  font-weight: 200 !important;
  /* width: 100vw; */
}

.pincode-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10%;
}

@media screen and (max-width: 680px) {
  .pincode-input {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    font-size: 30px;
    text-align: center;
    font-weight: 300;
    margin: 5px;
  }
}

.shake {
  animation: shakeMe 0.7s ease-out;
  backface-visibility: hidden;
}

/* shaky animation */

@keyframes shakeMe {
  0% {
    transform: translateX(-50px);
  }
  10% {
    transform: translateX(50px);
  }
  20% {
    transform: translateX(-50px);
  }
  30% {
    transform: translateX(50px);
  }
  40% {
    transform: translateX(-50px);
  }
  50% {
    transform: translateX(50px);
  }
  60% {
    transform: translateX(-50px);
  }
  70% {
    transform: translateX(50px);
  }
  80% {
    transform: translateX(-50px);
  }

  90% {
    transform: translateX(50px);
  }
  100% {
    transform: translate(0);
  }
}

@keyframes slideInLeft {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  70% {
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}
