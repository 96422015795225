@import url("https://fonts.googleapis.com/css?family=Montserrat:700,500,400");

body {
  font-family: "Montserrat", sans-serif;
}

.disabledUI {
  width: 70vw;
  margin: 0 auto;
  padding: 50px;
  height: 100%;
  background-image: linear-gradient(
    to right bottom,
    rgb(150, 209, 13),
    rgb(29, 233, 165)
  );
  border-radius: 5px;
  box-shadow: 1px 20px 30px rgba(0, 0, 0, 0.15);
}

.disabledUI__header {
  font-size: 50px;
  font-weight: 700;
  font-family: "Montserrat";
  display: inline-block;
  background-image: linear-gradient(to right, #229b1e, #28b485);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1.5px;
  margin: 10px;
  padding: 30px;
  text-shadow: 0 20px 100px rgba(0, 0, 0, 0.38);
}

.u-center {
  text-align: center;
}

.disabledUI__button {
  font-size: 15px;
  font-weight: 500;
  font-family: "Montserrat";
  color: white;
  display: inline-block;
  padding: 10px 20px;
  border: none;
  background-color: rgb(6, 172, 116);
  border-radius: 3px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  margin-bottom: 30px;
  transition: all 0.2s ease-out;
}

.disabledUI__button:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
}

.disabledUI__button:active {
  transform: translateY(1px);
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);
}

.disableUI__about p {
  word-spacing: 2px;
  text-align: left;
  color: rgb(77, 76, 75);
  font-family: "Montserrat";
  font-weight: 400;
}

@media (max-width: 680px) {
  .disabledUI__header {
    font-size: 28px;
    text-align: center;
    padding: 0;
    margin: 10px;
  }

  .disabledUI__button {
    font-size: 13px;
    padding: 10px;
  }

  .disableUI__about p {
    text-align: center;
  }
}

@media (max-width: 400px) {
  .disabledUI__header {
    font-size: 20px;
    text-align: center;
  }
}
