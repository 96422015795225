.floating-alert {
  display: none;
  position: absolute;
  z-index: 999;
  top: 38px;
  left: 50%;
  transform: translateX(-50%);
  -moz-animation: floatingAlert ease-in 5s forwards;
  -webkit-animation: floatingAlert ease-in 5s forwards;
  -o-animation: floatingAlert ease-in 5s forwards;
  animation: floatingAlert ease-in 5s forwards;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.floating-alerts .floating-alert:last-of-type {
  display: block;
}

@keyframes floatingAlert {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: translateX(-50%) scale(1.2);
  }

  9% {
    opacity: 1;
    visibility: visible;
    transform: translateX(-50%) scale(1);
  }

  91% {
    opacity: 1;
    visibility: visible;
    transform: translateX(-50%) scale(1);
  }

  100% {
    opacity: 0;
    visibility: hidden;
    transform: translateX(-50%) scale(1.2);
  }
}

@-webkit-keyframes floatingAlert {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: translateX(-50%) scale(1.2);
  }

  9% {
    opacity: 1;
    visibility: visible;
    transform: translateX(-50%) scale(1);
  }

  91% {
    opacity: 1;
    visibility: visible;
    transform: translateX(-50%) scale(1);
  }

  100% {
    opacity: 0;
    visibility: hidden;
    transform: translateX(-50%) scale(1.2);
  }
}

.small-header-avatar {
  width: 32px;
  height: 32px;
  border-radius: 16px;
}

.custom-tooltip {
  padding: 8px 10px !important;
}

/* Dots Loading Animation */
.dots-loading {
  margin: 0 auto;
  text-align: center;
}

.dots-loading::before,
.dots-loading::after {
  content: " ";
}

.dots-loading div,
.dots-loading::before,
.dots-loading::after {
  margin: 35px 5px;
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #c4c4c4;
  opacity: 0;
}

.dots-loading::before {
  -moz-animation: opacitychange 1s ease-in-out infinite;
  -webkit-animation: opacitychange 1s ease-in-out infinite;
  -o-animation: opacitychange 1s ease-in-out infinite;
  animation: opacitychange 1s ease-in-out infinite;
}

.dots-loading div {
  -moz-animation: opacitychange 1s ease-in-out 0.33s infinite;
  -webkit-animation: opacitychange 1s ease-in-out 0.33s infinite;
  -o-animation: opacitychange 1s ease-in-out 0.33s infinite;
  animation: opacitychange 1s ease-in-out 0.33s infinite;
  -webkit-animation-fill-mode: infinite;
  animation-fill-mode: infinite;
}

.dots-loading::after {
  -moz-animation: opacitychange 1s ease-in-out 0.66s infinite;
  -webkit-animation: opacitychange 1s ease-in-out 0.66s infinite;
  -o-animation: opacitychange 1s ease-in-out 0.66s infinite;
  animation: opacitychange 1s ease-in-out 0.66s infinite;
  -webkit-animation-fill-mode: infinite;
  animation-fill-mode: infinite;
}

@keyframes opacitychange {
  0%,
  100% {
    opacity: 0;
  }

  60% {
    opacity: 1;
  }
}

@-webkit-keyframes opacitychange {
  0%,
  100% {
    opacity: 0;
  }

  60% {
    opacity: 1;
  }
}
