/*!

 =========================================================
 * Awesome Landing Page - v1.2.2
 =========================================================
 
 * Product Page: https://www.creative-tim.com/product/awesome-landing-page
 * Copyright 2017 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/awesome-landing-page/blob/master/LICENSE.md)
 
 =========================================================
 
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

/*           Font Smoothing      */
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p, .navbar, .brand, .btn-simple, a, .td-name, td {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: "Open Sans";
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
  font-weight: 400;
  margin: 30px 0 15px;
}

h1, .h1 {
  font-size: 52px;
}

h2, .h2 {
  font-size: 36px;
}

h3, .h3 {
  font-size: 28px;
  margin: 20px 0 10px;
}

h4, .h4 {
  font-size: 22px;
  line-height: 30px;
}

h5, .h5 {
  font-size: 18px;
  margin-bottom: 15px;
}

h6, .h6 {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

p {
  font-size: 16px;
  line-height: 1.5;
}

/*     General overwrite     */
body {
  font-family: "Open Sans";
}

a {
  color: #2ca8ff;
}
a:hover, a:focus {
  color: #109cff;
  text-decoration: none;
}


a:focus, a:active {
  outline: 0;
}

.navbar {
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  transition: all 300ms linear;
}

.btn-hover, i {
  -webkit-transition: all 150ms linear;
  -moz-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  -ms-transition: all 150ms linear;
  transition: all 150ms linear;
}

.zoo{
  color: white;
  background-color: #cecece;
  text-align: center;

}

.myPlayButtons {
  width: 80px;
  height: 30px;
}

.myPlayButton {
  width: 160px;
  height: 80px;
  margin-top: 20px;
}

.container .nav > li > a:focus,
.container .nav > li > a:hover {
  text-decoration: none;
  background-color: transparent;
}

.location-button {
  cursor: pointer;
  borderRadius: 25px;
  backgroundColor: #fff !important;
  color: #000;
  marginRight: 10px;
  padding: 10px
}

.contact-form-container{
  padding-left: 120px !important;
  padding-right: 120px !important;
}

.cheese{
  background-color:white !important;
}

.location-map {
  height: 200px;
  margin-top: 40px;
  margin-bottom: 40px;
}
.location-iframe {
  height: 100%;
  width: 90%;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  width: 100% !important;
  height: 30% !important;

}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}


.iconFriends{

  width: 16px;
  height: 16px;
  border: none;
  display:inline-block;
  color: #d82027 !important;
}
.white{
  background-color: white !important;
}

.fa {
  width: 18px;
  text-align: center;
}

.btn {
  border-width: 2px;
  background-color: transparent;
  font-weight: 400;
  opacity: 0.8;
  filter: alpha(opacity=80);
  padding: 8px 16px;
  border-color: #888888;
  color: #888888;
}
.btn:hover, .btn:focus, .btn:active, .btn.active, .open > .btn.dropdown-toggle {
  background-color: transparent;
  color: #777777;
  border-color: #777777;
}
.btn:disabled, .btn[disabled], .btn.disabled {
  background-color: transparent;
  border-color: #888888;
}
.btn.btn-fill {
  color: white;
  background-color: #888888;
  opacity: 1;
  filter: alpha(opacity=100);
}
.btn.btn-fill:hover, .btn.btn-fill:focus, .btn.btn-fill:active, .btn.btn-fill.active, .open > .btn.btn-fill.dropdown-toggle {
  background-color: #777777;
  color: white;
}
.btn.btn-fill .caret {
  border-top-color: white;
}
.btn .caret {
  border-top-color: #888888;
}
.btn:hover, .btn:focus {
  opacity: 1;
  filter: alpha(opacity=100);
  outline: 0 !important;
}
.btn:active, .btn.active, .open > .btn.dropdown-toggle {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0 !important;
}
.btn.btn-icon {
  padding: 8px;
}
.contact{
  color: white;
  background-color: #d82027 ;
  font-size: 25px !important;
}
.contact1{
  color: white;
  background-color: #d82027;

}
.iframe{
  background-position: 100% 50%;
  margin-right: 80px;
  width:300px;
  height:200px;
  frameBorder:0px;
  aria-hidden:"false";
  tabIndex:0;
  allowFullScreen:"";
  border: 0px
}
.bottom{
  margin-bottom: 50px;
}
.vid{
  margin-right: 40px;

}
.bottom-left {
  position: absolute;
  max-width: 300px;
  width: auto;
  font-family:'Open Sans';
  font-size:20px;
  height:auto;
  bottom: 100px;
  left: 16px;
  color: white;
  background-color: rgba(249, 155, 28, 0.7);
  padding: 5px;
  text-align: center !important;
}
.slide2 {
  position: absolute;
  width: auto;
  font-family:'Open Sans';
  font-size:20px;
  height:auto;
  max-width: 300px;
  padding: 5px;
  bottom: 130px;
  left: 16px;
  color: white;
  background-color: rgba(249, 155, 28, 0.7);
  text-align: center !important;
}
.slide3 {
  position: absolute;
  max-width: 300px;
  height:auto;
  width: auto;
  bottom: 100px;
  left: 16px;
  background-color: rgba(249, 155, 28, 0.7);
  padding: 5px;
  font-family: 'Open Sans';
  font-size:20px;
  color: white;
  text-align: center !important;
}
/*.slide4 {
  position: absolute;
  width: 300px;
  font-family: Arial;
  font-size:20px;
  height:70px;
  bottom: 150px;
  left: 16px;
  color: white;
  background-color: orange;
  opacity: 70%;
}*/


.Select{
  height: 150px;

}
.col1 {
  float:left;
  margin-left:80px;
  margin-top: 30px;
  margin-bottom: 55px !important;
}
.tex2{
  float:left;
  margin-left:150px;
}
.tex{
  float:right;
  margin-right:80px;
}

.col2 {
  float:left;
  margin-left:230px;
  margin-top: 30px;
  margin-bottom: 55px !important;
}
.col3 {
  float:right;
  margin-right:80px;
  margin-top: 30px;
  margin-bottom: 55px !important;
}
.contact:hover{
  color: white !important;
  background-color: #d82027;
  border-color: #d82027;
}
.visit{
  color: red;
  background-color: white;
  border-color: #d82027 !important ;
  font-size: 25px !important;
  margin-left: 15px !important;
}
.visit:hover{
  color: #d82027 !important;
  background-color: white;
  border-color: #d82027;

}
.btn-primary {
  border-color: #3472f7;
  color: #3472f7;
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open > .btn-primary.dropdown-toggle {
  background-color: transparent;
  color: #1d62f0;
  border-color: #1d62f0;
}
.btn-primary:disabled, .btn-primary[disabled], .btn-primary.disabled {
  background-color: transparent;
  border-color: #3472f7;
}
.btn-primary.btn-fill {
  color: white;
  background-color: #3472f7;
  opacity: 1;
  filter: alpha(opacity=100);
}
.btn-primary.btn-fill:hover, .btn-primary.btn-fill:focus, .btn-primary.btn-fill:active, .btn-primary.btn-fill.active, .open > .btn-primary.btn-fill.dropdown-toggle {
  background-color: #1d62f0;
  color: white;
}
.btn-primary.btn-fill .caret {
  border-top-color: white;
}
.btn-primary .caret {
  border-top-color: #3472f7;
}

.btn-success {
  border-color: #3ABE41;
  color: #3ABE41;
  font-size: 25px !important;
}
.btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active, .open > .btn-success.dropdown-toggle {
  background-color: transparent;
  color: #3ABE41;
  border-color: #34aa3a;
}
.btn-success:disabled, .btn-success[disabled], .btn-success.disabled {
  background-color: transparent;
  border-color: #3ABE41;
}
.btn-success.btn-fill {
  color: white;
  background-color: #3ABE41;
  opacity: 1;
  filter: alpha(opacity=100);
}
.btn-success.btn-fill:hover, .btn-success.btn-fill:focus, .btn-success.btn-fill:active, .btn-success.btn-fill.active, .open > .btn-success.btn-fill.dropdown-toggle {
  background-color: #34aa3a;
  color: white;
}
.btn-success.btn-fill .caret {
  border-top-color: white;
}
.btn-success .caret {
  border-top-color: #3ABE41;
}

.btn-info {
  border-color: #2ca8ff;
  color: #2ca8ff;
}
.btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active, .open > .btn-info.dropdown-toggle {
  background-color: transparent;
  color: #109cff;
  border-color: #109cff;
}
.btn-info:disabled, .btn-info[disabled], .btn-info.disabled {
  background-color: transparent;
  border-color: #2ca8ff;
}
.btn-info.btn-fill {
  color: white;
  background-color: #2ca8ff;
  opacity: 1;
  filter: alpha(opacity=100);
}
.btn-info.btn-fill:hover, .btn-info.btn-fill:focus, .btn-info.btn-fill:active, .btn-info.btn-fill.active, .open > .btn-info.btn-fill.dropdown-toggle {
  background-color: #109cff;
  color: white;
}
.btn-info.btn-fill .caret {
  border-top-color: white;
}
.btn-info .caret {
  border-top-color: #2ca8ff;
}

.btn-warning {
  border-color: #FFA17F;
  color: #FFA17F;
}
.btn-warning:hover, .btn-warning:focus, .btn-warning:active, .btn-warning.active, .open > .btn-warning.dropdown-toggle {
  background-color: transparent;
  color: #ff967f;
  border-color: #ff967f;
}
.btn-warning:disabled, .btn-warning[disabled], .btn-warning.disabled {
  background-color: transparent;
  border-color: #ff9500;
}
.btn-warning.btn-fill {
  color: white;
  background-color: #FFA17F;
  opacity: 1;
  filter: alpha(opacity=100);
}
.btn-warning.btn-fill:hover, .btn-warning.btn-fill:focus, .btn-warning.btn-fill:active, .btn-warning.btn-fill.active, .open > .btn-warning.btn-fill.dropdown-toggle {
  background-color: #ff967f;
  color: white;
}
.btn-warning.btn-fill .caret {
  border-top-color: white;
}
.btn-warning .caret {
  border-top-color: #ff9500;
}

.btn-danger {
  border-color: #E01724;
  color: #E01724;
}
.btn-danger:hover, .btn-danger:focus, .btn-danger:active, .btn-danger.active, .open > .btn-danger.dropdown-toggle {
  background-color: transparent;
  color: #C91420;
  border-color: #C91420;
}
.btn-danger:disabled, .btn-danger[disabled], .btn-danger.disabled {
  background-color: transparent;
  border-color: #E01724;
}
.btn-danger.btn-fill {
  color: white;
  background-color: #E01724;
  opacity: 1;
  filter: alpha(opacity=100);
}
.btn-danger.btn-fill:hover, .btn-danger.btn-fill:focus, .btn-danger.btn-fill:active, .btn-danger.btn-fill.active, .open > .btn-danger.btn-fill.dropdown-toggle {
  background-color: #C91420;
  color: white;
}
.btn-danger.btn-fill .caret {
  border-top-color: white;
}
.btn-danger .caret {
  border-top-color: #E01724;
}

.btn-neutral {
  border-color: white;
  color: white;
}
.btn-neutral:hover, .btn-neutral:focus, .btn-neutral:active, .btn-neutral.active, .open > .btn-neutral.dropdown-toggle {
  background-color: transparent;
  color: white;
  border-color: white;
}
.btn-neutral:disabled, .btn-neutral[disabled], .btn-neutral.disabled {
  background-color: transparent;
  border-color: white;
}
.btn-neutral.btn-fill {
  color: white;
  background-color: white;
  opacity: 1;
  filter: alpha(opacity=100);
}
.btn-neutral.btn-fill:hover, .btn-neutral.btn-fill:focus, .btn-neutral.btn-fill:active, .btn-neutral.btn-fill.active, .open > .btn-neutral.btn-fill.dropdown-toggle {
  background-color: white;
  color: white;
}
.btn-neutral.btn-fill .caret {
  border-top-color: white;
}
.btn-neutral .caret {
  border-top-color: white;
}
.btn-neutral:active, .btn-neutral.active, .open > .btn-neutral.dropdown-toggle {
  background-color: white;
  color: #888888;
}
.btn-neutral.btn-fill, .btn-neutral.btn-fill:hover, .btn-neutral.btn-fill:focus {
  color: #888888;
}
.btn-neutral.btn-simple:active, .btn-neutral.btn-simple.active {
  background-color: transparent;
}

.btn:disabled, .btn[disabled], .btn.disabled {
  opacity: 0.5;
  filter: alpha(opacity=50);
}

.btn-round {
  border-width: 1px;
  border-radius: 30px !important;
  padding: 9px 18px;
}
.btn-round.btn-icon {
  padding: 9px;
}

.btn-simple {
  border: 0;
  font-size: 16px;
  padding: 8px 16px;
}
.btn-simple.btn-icon {
  padding: 8px;
}

.btn-lg {
  font-size: 18px;
  border-radius: 6px;
  padding: 14px 30px;
  font-weight: 400;
}
.btn-lg.btn-round {
  padding: 15px 30px;
}
.btn-lg.btn-simple {
  padding: 16px 30px;
}

.btn-sm {
  font-size: 12px;
  border-radius: 3px;
  padding: 5px 10px;
}
.btn-sm.btn-round {
  padding: 6px 10px;
}
.btn-sm.btn-simple {
  padding: 7px 10px;
}

.btn-xs {
  font-size: 12px;
  border-radius: 3px;
  padding: 1px 5px;
}
.btn-xs.btn-round {
  padding: 2px 5px;
}
.btn-xs.btn-simple {
  padding: 3px 5px;
}

.btn-wd {
  min-width: 140px;
}

.btn-group.select {
  width: 100%;
}

.btn-group.select .btn {
  text-align: left;
}

.btn-group.select .caret {
  position: absolute;
  top: 50%;
  margin-top: -1px;
  right: 8px;
}

.section {
  padding: 30px 0;
  position: relative;
  background-color: #FFFFFF;
  margin-top: 20px;
}

.section-gray {
  background-color: #EEEEEE;
}

.section-white {
  background-color: #FFFFFF;
}

.navbar {
  border: 0;
  font-size: 16px;
}
.navbar .navbar-brand {
  font-weight: 600;
  margin: 5px 0px;
  padding: 20px 15px;
  font-size: 20px;
}
.navbar .navbar-nav > li > a {
  padding: 10px 15px;
  margin: 15px 3px;
}
.navbar .navbar-nav > li > a.btn {
  margin: 15px 3px;
  padding: 8px 16px;
}
.navbar .navbar-nav > li > a.btn-round {
  margin: 16px 3px;
}
.navbar .navbar-nav > li > a [class^="fa"] {
  font-size: 19px;
  position: relative;
  top: 1px;
}
.navbar .btn {
  margin: 15px 3px;
  font-size: 14px;
}
.navbar .btn-simple {
  font-size: 16px;
}

.navbar-transparent .navbar-brand, [class*="navbar-ct"] .navbar-brand {
  color: white;
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.navbar-transparent .navbar-brand:focus, .navbar-transparent .navbar-brand:hover, [class*="navbar-ct"] .navbar-brand:focus, [class*="navbar-ct"] .navbar-brand:hover {
  background-color: transparent;
  opacity: 1;
  filter: alpha(opacity=100);
}
.navbar-transparent .navbar-nav > li > a, [class*="navbar-ct"] .navbar-nav > li > a {
  color: white;
  border-color: white;
  opacity: 0.8;
  filter: alpha(opacity=80);
}
.navbar-transparent .navbar-nav > .active > a,
.navbar-transparent .navbar-nav > .active > a:hover,
.navbar-transparent .navbar-nav > .active > a:focus,
.navbar-transparent .navbar-nav > li > a:hover,
.navbar-transparent .navbar-nav > li > a:focus, [class*="navbar-ct"] .navbar-nav > .active > a,
[class*="navbar-ct"] .navbar-nav > .active > a:hover,
[class*="navbar-ct"] .navbar-nav > .active > a:focus,
[class*="navbar-ct"] .navbar-nav > li > a:hover,
[class*="navbar-ct"] .navbar-nav > li > a:focus {
  background-color: transparent;
  border-radius: 3px;
  color: white;
  opacity: 1;
  filter: alpha(opacity=100);
}
.navbar-transparent .navbar-nav > .dropdown > a .caret,
.navbar-transparent .navbar-nav > .dropdown > a:hover .caret,
.navbar-transparent .navbar-nav > .dropdown > a:focus .caret, [class*="navbar-ct"] .navbar-nav > .dropdown > a .caret,
[class*="navbar-ct"] .navbar-nav > .dropdown > a:hover .caret,
[class*="navbar-ct"] .navbar-nav > .dropdown > a:focus .caret {
  border-bottom-color: white;
  border-top-color: white;
}
.navbar-transparent .navbar-nav > .open > a,
.navbar-transparent .navbar-nav > .open > a:hover,
.navbar-transparent .navbar-nav > .open > a:focus, [class*="navbar-ct"] .navbar-nav > .open > a,
[class*="navbar-ct"] .navbar-nav > .open > a:hover,
[class*="navbar-ct"] .navbar-nav > .open > a:focus {
  background-color: transparent;
  color: white;
  opacity: 1;
  filter: alpha(opacity=100);
}
.navbar-transparent .btn-default, [class*="navbar-ct"] .btn-default {
  color: white;
  border-color: white;
}
.navbar-transparent .btn-default.btn-fill, [class*="navbar-ct"] .btn-default.btn-fill {
  color: #9a9a9a;
  background-color: white;
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.navbar-transparent .btn-default.btn-fill:hover,
.navbar-transparent .btn-default.btn-fill:focus,
.navbar-transparent .btn-default.btn-fill:active,
.navbar-transparent .btn-default.btn-fill.active,
.navbar-transparent .open .dropdown-toggle.btn-fill.btn-default, [class*="navbar-ct"] .btn-default.btn-fill:hover,
[class*="navbar-ct"] .btn-default.btn-fill:focus,
[class*="navbar-ct"] .btn-default.btn-fill:active,
[class*="navbar-ct"] .btn-default.btn-fill.active,
[class*="navbar-ct"] .open .dropdown-toggle.btn-fill.btn-default {
  border-color: white;
  opacity: 1;
  filter: alpha(opacity=100);
}

.navbar-transparent .dropdown-menu .divider {
  background-color: rgba(255, 255, 255, 0.2);
}

.footer {
  background-color: white;
  line-height: 20px;
  position: absolute !important;
  margin:0px !important;
  padding:0px !important;
  width:auto !important;
}
.footer nav > ul {
  list-style: none;
  margin: 0;
  padding: 0;
  font-weight: normal;
}
.footer nav > ul a:not(.btn) {
  color: #9a9a9a;
  display: block;
  margin-bottom: 3px;
}
.footer nav > ul a:not(.btn):hover, .footer nav > ul a:not(.btn):focus {
  color: #777777;
}
.footer .social-area {
  padding: 15px 0;
}
.footer .social-area h5 {
  padding-bottom: 15px;
}
.footer .social-area > a:not(.btn) {
  color: #9a9a9a;
  display: inline-block;
  vertical-align: top;
  padding: 10px 5px;
  font-size: 20px;
  font-weight: normal;
  line-height: 20px;
  text-align: center;
}
.footer .social-area > a:not(.btn):hover, .footer .social-area > a:not(.btn):focus {
  color: #777777;
}
.footer .copyright {
  color: #777777;
  padding: 10px 15px;
  margin: 15px 3px;
  line-height: 20px;
  text-align: center;
}
.footer hr {
  border-color: #dddddd;
}
.footer .title {
  color: #777777;
}

.footer:not(.footer-big) nav > ul {
  font-size: 16px;
}
.footer:not(.footer-big) nav > ul li {
  margin-left: 20px;
  float: left;
}
.footer:not(.footer-big) nav > ul a {
  padding: 10px 0px;
  margin: 15px 10px 15px 0px;
}

.footer-default {
  background-color: whitesmoke;
}

.btn-social {
  opacity: 0.85;
  padding: 8px 9px;
}
.btn-social .fa {
  font-size: 18px;
  vertical-align: middle;
  display: inline-block;
}
.btn-social.btn-simple {
  padding: 9px 5px;
  font-size: 16px;
}
.btn-social.btn-simple .fa {
  font-size: 20px;
  position: relative;
  top: -2px;
  width: 24px;
}

.btn-facebook {
  border-color: #3b5998;
  color: #3b5998;
}
.btn-facebook:hover, .btn-facebook:focus, .btn-facebook:active, .btn-facebook.active, .open > .btn-facebook.dropdown-toggle {
  background-color: transparent;
  color: #3b5998;
  border-color: #3b5998;
  opacity: 1;
}
.btn-facebook:disabled, .btn-facebook[disabled], .btn-facebook.disabled {
  background-color: transparent;
  border-color: #3b5998;
}
.btn-facebook.btn-fill {
  color: white;
  background-color: #3b5998;
  opacity: 0.9;
}
.btn-facebook.btn-fill:hover, .btn-facebook.btn-fill:focus, .btn-facebook.btn-fill:active, .btn-facebook.btn-fill.active, .open > .btn-facebook.btn-fill.dropdown-toggle {
  background-color: #3b5998;
  color: white;
  opacity: 1;
}

.btn-twitter {
  border-color: #55acee;
  color: #55acee;
}
.btn-twitter:hover, .btn-twitter:focus, .btn-twitter:active, .btn-twitter.active, .open > .btn-twitter.dropdown-toggle {
  background-color: transparent;
  color: #55acee;
  border-color: #55acee;
  opacity: 1;
}
.btn-twitter:disabled, .btn-twitter[disabled], .btn-twitter.disabled {
  background-color: transparent;
  border-color: #55acee;
}
.btn-twitter.btn-fill {
  color: white;
  background-color: #55acee;
  opacity: 0.9;
}
.btn-twitter.btn-fill:hover, .btn-twitter.btn-fill:focus, .btn-twitter.btn-fill:active, .btn-twitter.btn-fill.active, .open > .btn-twitter.btn-fill.dropdown-toggle {
  background-color: #55acee;
  color: white;
  opacity: 1;
}

.btn-pinterest {
  border-color: #cc2127;
  color: #cc2127;
}
.btn-pinterest:hover, .btn-pinterest:focus, .btn-pinterest:active, .btn-pinterest.active, .open > .btn-pinterest.dropdown-toggle {
  background-color: transparent;
  color: #cc2127;
  border-color: #cc2127;
  opacity: 1;
}
.btn-pinterest:disabled, .btn-pinterest[disabled], .btn-pinterest.disabled {
  background-color: transparent;
  border-color: #cc2127;
}
.btn-pinterest.btn-fill {
  color: white;
  background-color: #cc2127;
  opacity: 0.9;
}
.btn-pinterest.btn-fill:hover, .btn-pinterest.btn-fill:focus, .btn-pinterest.btn-fill:active, .btn-pinterest.btn-fill.active, .open > .btn-pinterest.btn-fill.dropdown-toggle {
  background-color: #cc2127;
  color: white;
  opacity: 1;
}
.border-top{
  border-top: 1px solid #e2e2e2;
}

.parallax .parallax-image  {
  width: 100%;
  position: absolute;
  height: 50%;

}

.parallax .parallax-image.second-image{
  position: absolute;
  right: -200px;
  top: 0;
}

.landing-page ol{
  font-weight: 300;
}

.landing-page .section p{
  margin-top: 30px;
  line-height: 28px;
  font-size: 18px;
  font-weight: 200;
}
.landing-page .section-description h5 {
  margin-top: 40px;
}
.landing-page .img-container {
  border-radius: 10px;
  overflow: hidden;
}
.landing-page .img-container img {
  width: 100%;
}
.landing-page .section-testimonials .carousel-control {
  left: -70px;
}
.landing-page .section-testimonials .carousel-control .fa {
  top: 40%;
}
.landing-page .section-testimonials .carousel-control.right {
  right: -40px;
  left: auto;
}
.landing-page .section-clients {
  padding: 50px 0;
}
.landing-page .section-clients .info .icon {
  background: transparent;
}
.landing-page .section-clients .icon i {
  color: #AAAAAA;
}
.landing-page .section-clients h3 {
  margin-top: 10px;
}
.landing-page .social-line {
  border-bottom: 1px solid #dddddd;
}
.landing-page .carousel-indicators {
  bottom: -50px;
}
.landing-page .carousel-indicators li {
  margin: 1px 10px;
  border-color: #888888;
}
.landing-page .carousel-indicators .active{
  background-color: #888888;
}
.landing-page .carousel-indicators.carousel-indicators-blue li {
  border-color: #00bbff;
}
.landing-page .carousel-indicators.carousel-indicators-blue .active {
  background-color: #00bbff;
}
.landing-page .carousel-indicators.carousel-indicators-green li {
  border-color: #3ABE41;
}
.landing-page .carousel-indicators.carousel-indicators-green .active {
  background-color: #3ABE41;
}
.landing-page .carousel-indicators.carousel-indicators-orange li {
  border-color: #FFA17F;
}
.landing-page .carousel-indicators.carousel-indicators-orange .active {
  background-color: #FFA17F;
}
.landing-page .carousel-indicators.carousel-indicators-red li {
  border-color: #E01724;
}
.landing-page .carousel-indicators.carousel-indicators-red .active {
  background-color: #E01724;
}
.landing-page .carousel-inner > .item > img,
.landing-page .carousel-inner > .item > a > img {
  display: block;
  margin: 0 auto;
  height: auto;
  border-radius: 10px;
  width: 100%;
}
.landing-page .carousel.fade {
  opacity: 1;
}
.landing-page .carousel.fade .item {
  -moz-transition: opacity ease-in-out .7s;
  -o-transition: opacity ease-in-out .7s;
  -webkit-transition: opacity ease-in-out .7s;
  transition: opacity ease-in-out .7s;
  left: 0 !important;
  opacity: 0;
  top: 0;
  position: absolute;
  width: 100%;
  display: block !important;
  z-index: 1;
}
.landing-page .carousel.fade .item:first-child {
  top: auto;
  position: relative;
}
.landing-page .carousel.fade .item.active {
  opacity: 1;
  -moz-transition: opacity ease-in-out .7s;
  -o-transition: opacity ease-in-out .7s;
  -webkit-transition: opacity ease-in-out .7s;
  transition: opacity ease-in-out .7s;
  z-index: 2;
}


.logo-container .logo{
  overflow: hidden;
  /*border-radius: 30%;*/
  /*border: 1px solid #333333;*/
  width: 180px;
  float: left;
  padding:5px;
  margin-top: 20px;
}

.logo-container .brand{
  font-size: 18px;
  color: #FFFFFF;
  line-height: 20px;
  float: left;
  margin-left: 10px;
  margin-top: 5px;
  width: 55px;
  height: 50px;
}
.logo-container{
  margin-top: 10px;
}
.logo-container .logo img{
  width: 100%;

}

.landing-page1 .container{
  max-width: 970px;

}
.banner {
  width: 99%;
  height: 150px;
  z-index: 20;
  position: absolute;
  top: 0;
  margin-left: 0px;
  margin-right: 0px;
  left: 0;
  right: 0;
}
.landing-page2 .container{
  max-width: 1170px;
}

.header-text{
  text-transform: uppercase;
  font-weight: 200;
  color: #d82027;
  margin-bottom: 20px;
  font-size: 30px;
  position: relative;
  line-height: 24px;
}

.landing-page .navbar-top {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  background-color: white;
}

.landing-page .parallax {
  width: 100%;
  height: 630px;
  overflow: hidden;
  display: block;
  background-size: 100%;
}

.landing-page .parallax .container{
  z-index: 2;
  position: absolute;
  top: 0;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.mybuttons span{
  background-color: #4CAF50; /* Green */
  border: 1px solid green;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  float: right;
}

.filter-gradient{
  position: relative;
}
.filter-gradient:after{
  background-size: 2000px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 1;
  content: "";
  opacity: .7;
}
.filter-gradient.gray:after{
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzIzMjUyNiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM0MTQzNDUiIHN0b3Atb3BhY2l0eT0iMC44NyIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top,  rgba(35,37,38,1) 0%, rgba(65,67,69,0.87) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(35,37,38,1)), color-stop(100%,rgba(65,67,69,0.87))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,  rgba(35,37,38,1) 0%,rgba(65,67,69,0.87) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top,  rgba(35,37,38,1) 0%,rgba(65,67,69,0.87) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,  rgba(35,37,38,1) 0%,rgba(65,67,69,0.87) 100%); /* IE10+ */
  background: linear-gradient(to bottom,  rgba(35,37,38,1) 0%,rgba(65,67,69,0.87) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#232526', endColorstr='#de414345',GradientType=0 ); /* IE6-8 */

}
.filter-gradient.blue:after{
  background: rgba(54, 151, 142, 0.8) ; /* Old browsers */
  background: -moz-radial-gradient(center, ellipse cover,  #1e5799 0%, rgba(54, 151, 142, 0.8)  0%, #0C526C 100%, #3d7eaa 100%, #182848 100%, #6e48aa 100%, #6e48aa 100%); /* FF3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%,#1e5799), color-stop(0%,rgba(54, 151, 142, 0.8) ), color-stop(100%,#0C526C), color-stop(100%,#3d7eaa), color-stop(100%,#182848), color-stop(100%,#6e48aa), color-stop(100%,#6e48aa)); /* Chrome,Safari4+ */
  background: -webkit-radial-gradient(center, ellipse cover,  #1e5799 0%,rgba(54, 151, 142, 0.8)  0%,#0C526C 100%,#3d7eaa 100%,#182848 100%,#6e48aa 100%,#6e48aa 100%); /* Chrome10+,Safari5.1+ */
  background: -o-radial-gradient(center, ellipse cover,  #1e5799 0%,rgba(54, 151, 142, 0.8)  0%,#0C526C 100%,#3d7eaa 100%,#182848 100%,#6e48aa 100%,#6e48aa 100%); /* Opera 12+ */
  background: -ms-radial-gradient(center, ellipse cover,  #1e5799 0%,rgba(54, 151, 142, 0.8)  0%,#0C526C 100%,#3d7eaa 100%,#182848 100%,#6e48aa 100%,#6e48aa 100%); /* IE10+ */
  background: radial-gradient(ellipse at center,  #1e5799 0%,rgba(54, 151, 142, 0.8)  0%,#0C526C 100%,#3d7eaa 100%,#182848 100%,#6e48aa 100%,#6e48aa 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='rgba(54, 151, 142, 0.8) ', endColorstr='#6e48aa',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
.filter-gradient.green:after{
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMTAwJSIgeDI9IjEwMCUiIHkyPSIwJSI+CiAgICA8c3RvcCBvZmZzZXQ9IjAlIiBzdG9wLWNvbG9yPSIjMzQ4ZjUwIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzU2YjRkMyIgc3RvcC1vcGFjaXR5PSIwLjg3Ii8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
  background: -moz-linear-gradient(45deg,  rgba(52,143,80,1) 0%, rgba(86, 211, 146, 0.75) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left bottom, right top, color-stop(0%,rgba(52,143,80,1)), color-stop(100%,rgba(86, 211, 146, 0.75))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(45deg,  rgba(52,143,80,1) 0%,rgba(86, 211, 146, 0.75) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(45deg,  rgba(52,143,80,1) 0%,rgba(86, 211, 146, 0.75) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(45deg,  rgba(52,143,80,1) 0%,rgba(86, 211, 146, 0.75) 100%); /* IE10+ */
  background: linear-gradient(45deg,  rgba(52,143,80,1) 0%,rgba(86, 211, 146, 0.75) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#348f50', endColorstr='#de56b4d3',GradientType=1 ); /* IE6-8 fallback on horizontal gradient */

}
.filter-gradient.orange:after{
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmYTE3ZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDIyM2UiIHN0b3Atb3BhY2l0eT0iMC44NyIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top,  rgba(255,161,127,1) 0%, rgba(0,34,62,0.87) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,161,127,1)), color-stop(100%,rgba(0,34,62,0.87))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,  rgba(255,161,127,1) 0%,rgba(0,34,62,0.87) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top,  rgba(255,161,127,1) 0%,rgba(0,34,62,0.87) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,  rgba(255,161,127,1) 0%,rgba(0,34,62,0.87) 100%); /* IE10+ */
  background: linear-gradient(to bottom,  rgba(255,161,127,1) 0%,rgba(0,34,62,0.87) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffa17f', endColorstr='#de00223e',GradientType=0 ); /* IE6-8 */

}
.filter-gradient.red:after{
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPHJhZGlhbEdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgY3g9IjUwJSIgY3k9IjUwJSIgcj0iNzUlIj4KICAgIDxzdG9wIG9mZnNldD0iMSUiIHN0b3AtY29sb3I9IiM4ZTAwMDkiIHN0b3Atb3BhY2l0eT0iMC44NyIvPgogICAgPHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjNTYxMTExIiBzdG9wLW9wYWNpdHk9IjEiLz4KICA8L3JhZGlhbEdyYWRpZW50PgogIDxyZWN0IHg9Ii01MCIgeT0iLTUwIiB3aWR0aD0iMTAxIiBoZWlnaHQ9IjEwMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-radial-gradient(center, ellipse cover,  rgba(224, 23, 36, 0.7)  1%, rgba(86,17,17,1) 100%); /* FF3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(1%,rgba(224, 23, 36, 0.7) ), color-stop(100%,rgba(86,17,17,1))); /* Chrome,Safari4+ */
  background: -webkit-radial-gradient(center, ellipse cover,  rgba(224, 23, 36, 0.7)  1%,rgba(86,17,17,1) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-radial-gradient(center, ellipse cover,  rgba(224, 23, 36, 0.7)  1%,rgba(86,17,17,1) 100%); /* Opera 12+ */
  background: -ms-radial-gradient(center, ellipse cover,  rgba(224, 23, 36, 0.7)  1%,rgba(86,17,17,1) 100%); /* IE10+ */
  background: radial-gradient(ellipse at center,  rgba(224, 23, 36, 0.7)  1%,rgba(86,17,17,1) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#de8e0009', endColorstr='#561111',GradientType=1 ); /* IE6-8 fallback on horizontal gradient */

}
.section-no-padding{
  padding-bottom: 0;
}

.landing-page .footer{
  padding: 0px;
  margin: 0px;
  width: 100%;
  position: absolute;
  left:0px;
  overflow-x: hidden;
}
.landing-page .parallax .parallax-background{
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.landing-page .parallax .parallax-background img{
  width: 100%;
  height: auto;
  position: relative;
}
.landing-page .parallax .parallax-image{
  width: 99%;
  margin: 0 auto;
  margin-top: 145px;
  float: right;
  text-align: center;
}

.landing-page .parallax .parallax-image img {
  height: auto;
  width: 100%;
}

.landing-page .parallax .parallax-image img.phone{
  width: 80%;
  float: right;
}

.landing-page .parallax .parallax-background img.flipped{
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}

.landing-page .parallax .parallax-image .screen {
  position: absolute;
  z-index: 3;
}

.landing-page .parallax .description{
  margin-top: 200px;
  color: white;
  padding: 5px;
}

.landing-page .section-presentation .description{
  padding: 50px 5px 0;
}

.landing-page .parallax .description h2{
  text-shadow: 0 1px 5px rgba(0,0,0,0.2);
  font-weight: 200;
  font-size: 40px;
}

.landing-page .parallax .description h5 {
  line-height: 1.4;
  font-weight: 400;
  text-shadow: 0 1px 5px rgba(0,0,0,0.5);
  font-size: 18px;
}

.landing-page .parallax .buttons{
  width: 100%;
  display: inline-block;
  margin-top: 40px;

}

.landing-page .parallax .buttons h4{
  color: white;
  display: inline-block;
}

.landing-page .parallax .buttons h4 ~ .btn{
  margin-top: -10px;
  margin-left: 20px;
}

.landing-page .parallax .buttons .btn-simple{
  font-size: 30px;
}



.landing-page .logos {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}
.landing-page .logos ul{
  display: inline-block;
  width: 100%;
  margin: 0px auto;
  padding-top: 48px;
}
.landing-page .logos li{
  display: inline-block;
  margin: 0 17px;
}

.landing-page .section-presentation {
  overflow: hidden;
  width: 100%;
  min-height: 600px;
  margin-top: 30px;
}

.landing-page .section-presentation img{
  position: absolute;
  top: 0;
  left: 0;
  height: 600px;
}

.landing-page .section-presentation .iphone-presentation {
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: auto;
}

.landing-page .section-no-padding .parallax {
  color: white;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 600px;
  text-align: center;
}

.landing-page .section-no-padding .parallax .info{
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform:    translateX(-50%);
  -ms-transform:     translateX(-50%);
  -o-transform:      translateX(-50%);
  transform:         translateX(-50%);
  z-index: 20;
  top: 150px;

}
.landing-page .section-no-padding .parallax .info h1{
  font-size: 40px;
  font-weight: 300;
}

.landing-page .section-no-padding .parallax .info  a{
  position: relative;
  z-index: 10;

}

.landing-page .section-no-padding .parallax .info .btn-lg{
  margin-top: 75px;
  padding: 14px 80px;
}

.landing-page .section-demo{
  margin-top: 50px;
}

.landing-page .section-demo a{
  margin-top: 20px;
}

.landing-page .section-demo .demo-image{
  position: relative;
  width: 100%;
  display: block;
}

.landing-page .section-demo .demo-image img{
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.landing-page .section-demo .row:first-child{
  margin-bottom: 100px;
}

.landing-page .section-demo .carousel-inner{
  border-radius: 10px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.23);
}

.landing-page .section-features{
  margin: 50px 0;
}

.landing-page .section-features .card{
  width: 100%;
  min-height: 120px;
  padding: 10px 30px;
  border: 1px solid #DDDDDD;
  border-radius: 10px;
  margin-top: 30px;
  text-align: center;
}
.landing-page .section-features .card .icon i{
  font-size: 63px;
  color: #9a9a9a;
  width: 85px;
  height: 85px;
  line-height: 80px;
  text-align: center;
  border-radius: 50%;
  margin: 0 auto;
  margin-top: 20px;
}

.landing-page .section-features .card.card-blue{
  border-color: #CCE7F5;
}
.landing-page .section-features .card.card-blue .icon i{
  color:#00bbff;
}
.landing-page .section-features .card.card-green{
  border-color: #BDF8C0;
}
.landing-page .section-features .card.card-green .icon i{
  color:#3ABE41;
}
.landing-page .section-features .card.card-orange{
  border-color: #FAD9CD;
}
.landing-page .section-features .card.card-orange .icon i{
  color:#ff967f;
}
.landing-page .section-features .card.card-red{
  border-color: #FFBFC3;
}
.landing-page .section-features .card.card-red .icon i{
  color:#E01724;
}
.landing-page .section-features .card  h4{
  font-weight: 300;
  font-size: 18px;
  margin: 30px 0 -15px;
}

.landing-page .section-features .card  p{
  font-size: 16px;
  line-height: 22px;
  color: #777;
}


.landing-page .section-testimonial{
  position: relative;
  width: 100%;
  min-height: 450px;
}
.landing-page .section-testimonial .carousel{
  margin-top: 50px;

}
.landing-page .section-testimonial .carousel-inner .item .mask{
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 5px solid #ddd;
  overflow: hidden;
  display: block;
  margin: 0 auto;
}

.landing-page .section-testimonial .carousel-inner .item img{
  width: 100%;
  height: auto;
  display: block;
}

.landing-page .section-testimonial .carousel-inner .item .carousel-testimonial-caption{
  text-align: center;
  max-width: 700px;
  margin: 0 auto;
}

.landing-page .section-testimonial .carousel-inner  h3{
  font-weight: 300;
  font-size: 30px;
  line-height: 46px;
}

.landing-page .section-testimonial .carousel-inner  p{
  margin-top: 10px;
  color: #9a9a9a;
}

.landing-page .section-testimonial .carousel-control {
  color: #333;
  text-shadow: none;
}

.landing-page .carousel.fade {
  opacity: 1;
}
.landing-page .carousel.fade .item {
  -moz-transition: opacity ease-in-out .7s;
  -o-transition: opacity ease-in-out .7s;
  -webkit-transition: opacity ease-in-out .7s;
  transition: opacity ease-in-out .7s;
  left: 0 !important;
  opacity: 0;
  top: 0;
  position: absolute;
  width: 100%;
  display: block !important;
  z-index: 1;
}
.landing-page .carousel.fade .item:first-child {
  top: auto;
  position: relative;
}
.landing-page .carousel.fade .item.active {
  opacity: 1;
  -moz-transition: opacity ease-in-out .7s;
  -o-transition: opacity ease-in-out .7s;
  -webkit-transition: opacity ease-in-out .7s;
  transition: opacity ease-in-out .7s;
  z-index: 2;
}

.fixed-plugin{
  position: fixed;
  top: 150px;
  right: 0;
  width: 64px;
  background: rgba(0,0,0,.3);
  z-index: 1031;
  border-radius: 8px 0 0 8px;
  text-align: center;
}
.fixed-plugin .fa-cog{
  color: #FFFFFF;
  /*     background-color: rgba(0,0,0,.2); */
  padding: 10px;
  border-radius: 0 0 6px 6px;
}
.fixed-plugin .dropdown-menu{
  right: 80px;
  left: auto;
  width: 440px;
}
.fixed-plugin .dropdown-menu:after, .fixed-plugin .dropdown-menu:before{
  right: 10px;
  margin-left: auto;
  left: auto;
}
.fixed-plugin .fa-circle-thin{
  color: #FFFFFF;
}
.fixed-plugin .active .fa-circle-thin{
  color: #00bbff;
}

.footer-dropdown{
  top: -120px !important;
}

.footer-dropdown:before, .footer-dropdown:after{
  top: 300px !important;
}

.fixed-plugin img{
  border-radius: 6px;
  width: 200px;
  max-height: 175px;
  float: left;
}

.fixed-plugin .active a,
.fixed-plugin a:hover{
  border-color: #00bbff;
}

.fixed-plugin .btn{
  margin: 10px 15px 0;
  color: #FFFFFF;
}
.fixed-plugin .btn:hover{
  color: #FFFFFF;
}
.fixed-plugin .badge{
  border: 3px solid #FFFFFF;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 23px;
  margin-right: 5px;
  position: relative;
  width: 23px;
}
.fixed-plugin .badge.active, .fixed-plugin .badge:hover{
  border-color: #00bbff;
}
.fixed-plugin .badge-white{
  background-color: #eeeeee;
}
.fixed-plugin .badge-gray{
  background-color: #999999;
}
.fixed-plugin .badge-black{
  background-color: #5d5d5d;
}
.fixed-plugin .badge-orange{
  background-color: #FFA17F;
}
.fixed-plugin .badge-green{
  background-color: #3ABE41;
}
.fixed-plugin .badge-blue{
  background-color: #2ca8ff;
}
.fixed-plugin .badge-red{
  background-color: #E01724;
}
.fa{
  width: auto;
}

.fixed-plugin h5{
  font-size: 1em;
}
.fixed-plugin .dropdown-menu li{
  display: block;
  float: left;
  width: 100%;
  padding: 5px;
}

.fixed-plugin li.adjustments-line,
.fixed-plugin li.header-title{
  width: 100%;
  height: 50px;
  min-height: inherit;
}

.fixed-plugin li.header-title{
  height: 30px;
  line-height: 40px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 0 15px;
}

.fixed-plugin .adjustments-line p{
  float: left;
  display: inline-block;
  margin-bottom: 0;
  font-size: 1em;
}
.fixed-plugin .adjustments-line .switch{
  float: right;
}
.fixed-plugin .dropdown-menu > li.adjustments-line > a{
  padding-right: 0;
  padding-left: 0;
  border-bottom: 1px solid #ddd;
  margin-left: 10px;
  margin-right: 10px;
}
.fixed-plugin .dropdown-menu > li:not(.adjustments-line) > a p{
  margin-top: 35px;
  font-size: 14px;
  margin-left: 14px;
}
.fixed-plugin .dropdown-menu > li > a:not(.switch-trigger){
  font-size: 16px;
  text-align: center;
  border-radius: 10px;
  background-color: #FFF;
  padding-left: 0;
  padding-right: 0;
  opacity: 1;
  cursor: pointer;
}

.fixed-plugin .dropdown-menu > li > a.switch-trigger:hover,
.fixed-plugin .dropdown-menu > li > a.switch-trigger:focus{
  background-color: transparent;
}
.fixed-plugin .dropdown-menu > .active > a:not(.switch-trigger),
.fixed-plugin .dropdown-menu > .active > a:not(.switch-trigger){
  border-color: #00bbff;
  background-color: #FFFFFF;
}

.fixed-plugin .dropdown-menu > li > a img{
  margin-top: auto;
  border: 3px solid transparent;
}

.fixed-plugin .dropdown-menu >li.active >a img{
  border-color: rgb(32, 195, 255);
}

.fixed-plugin .dropdown-menu >li > a:hover img{
  border-color: rgb(44, 168, 255);
}

.fixed-plugin .dropdown-menu > li.active > a p,
.fixed-plugin .dropdown-menu > li > a p,
.fixed-plugin .dropdown-menu > li.active > a:focus p{
  color: #777777;
}

.fixed-plugin .dropdown-menu > li > a:hover p{
  color: #333333;
}
.fixed-plugin .dropdown-menu:before,
.fixed-plugin .dropdown-menu:after{
  content: "";
  display: inline-block;
  position: absolute;
  top: 20px;
  width: 10px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
}
.fixed-plugin .dropdown-menu:before{
  border-bottom: 10px solid rgba(0, 0, 0, 0);
  border-left: 10px solid rgba(0,0,0,0.2);
  border-top: 10px solid rgba(0,0,0,0);
  right: -10px;
}

.fixed-plugin .dropdown-menu:after{
  border-bottom: 10px solid rgba(0, 0, 0, 0);
  border-left: 10px solid #FFFFFF;
  border-top: 10px solid rgba(0,0,0,0);
  right: -9px;
}

@media (max-width:768px){
  .fixed-plugin{
    top: 80px;
    right: 0px;
  }
  .main .container{
    margin-bottom: 50px;
  }
  #video_background{
    display: none;
  }

  .features-image {
    width: 300px;
    height: 250px;
  }
}


@media (min-width: 768px){
  .navbar-form {
    margin-top: 21px;
    margin-bottom: 21px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .navbar-nav > li > .dropdown-menu, .dropdown-menu{
    display: block;
    margin: 0;
    padding: 0;
    z-index: 9000;
    position: absolute;
    -webkit-border-radius: 10px;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.125);
    border-radius: 10px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    opacity: 0;
    -ms-filter: "alpha(opacity=0)";
    -webkit-filter: alpha(opacity=0);
    -moz-filter: alpha(opacity=0);
    -ms-filter: alpha(opacity=0);
    -o-filter: alpha(opacity=0);
    filter: alpha(opacity=0);
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -o-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
    -moz-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
    -o-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
    -ms-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
    transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);

  }
  .navbar-nav > li.open > .dropdown-menu, .open .dropdown-menu{
    -webkit-transform-origin: 29px -50px;
    -moz-transform-origin: 29px -50px;
    -o-transform-origin: 29px -50px;
    -ms-transform-origin: 29px -50px;
    transform-origin: 29px -50px;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    -ms-filter: none;
    -webkit-filter: none;
    -moz-filter: none;
    -ms-filter: none;
    -o-filter: none;
    filter: none;
    padding: 5px 10px 15px;
  }
  .dropdown .dropdown-menu{
    top: -30px;
  }
  .fixed-plugin .dropdown-menu:before,
  .fixed-plugin .dropdown-menu:after{
    content: "";
    display: inline-block;
    position: absolute;
    top: 50px;
    width: 16px;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
  }
  .fixed-plugin .dropdown-menu:before{
    border-bottom: 16px solid rgba(0, 0, 0, 0);
    border-left: 16px solid rgba(0,0,0,0.2);
    border-top: 16px solid rgba(0,0,0,0);
    right: -16px;
  }

  .fixed-plugin .dropdown-menu:after{
    border-bottom: 16px solid rgba(0, 0, 0, 0);
    border-left: 16px solid #FFFFFF;
    border-top: 16px solid rgba(0,0,0,0);
    right: -15px;
  }

  .features-image {
    width: 1100px;
    height: 500px;
  }

  .descriptions{
    marginTop: 40px;
    marginLeft: 20px;
  }
  .pull-right{
    margin-top: -15px;
  }
  .margins{
    margin:40px;
  }
  .spacing{
    margin:10px !important;
  }
}

@media (max-width: 767px){
  .fixed-plugin{
    position: absolute;
  }
  .fixed-plugin .dropdown-menu{
    top: 0;
    width: 220px;
  }
  .nav > li{
    text-align: center;
  }
  .navbar-toggle .icon-bar{
    background: white !important;
  }
  .navbar-toggle{
    padding: 16px 10px;
  }
  .logo-container .logo{
    margin-left: 20px;
  }
  .landing-page .wrapper{
    text-align: center;
  }
  .landing-page .navbar-top{
    background: rgba(0, 0, 0, 0.75);
  }
  .landing-page .parallax {
    height: 550px;
  }
  .landing-page .parallax .parallax-background img {
    width: auto;
    height: 100%;
  }
  .landing-page .parallax .description{
    margin-top: 65px;  }
  .landing-page .parallax .buttons{
    margin: 20px 0;
  }
  .landing-page .logos li{
    display: block;
    margin-top: 10px;
  }
  .landing-page .logos ul{
    padding-top: 20px;
  }
  .landing-page .section-presentation{
    margin: 0;
  }
  .landing-page .section-presentation{
    height: auto;
  }
  .landing-page .carousel-indicators{
    bottom: -30px;
  }
  .landing-page .carousel{
    min-height: 210px;
  }
  .footer nav.pull-left, .footer:not(.footer-big) nav > ul li, .pull-right {
    float: none !important;
  }

  .our-locations{
    text-align: center;
  }
  .location-map{
    height: 200px;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .pull-lefts{
    float: left !important;
  }
  .pull-rights{
    float: right !important;
  }
  .myPlayButton {
    width: 80px;
    height: 50px;
  }
  .parallax-image{
    width: 94% !important;
  }
  .image-size{
    width: 100% !important;
    height: 100% !important;
  }
  .each-fade .image-container {
    width: 100%;
    overflow: hidden;
  }
  .bottom-left {
    font-size:16px;
    margin-bottom: 20px;
  }
  .slide2 {
    font-size:16px;
  }
  .slide3 {
    font-size:14px;
    margin-bottom: 25px;
  }
  .each-fade .image-container {
    width: 100%;
  }
  .dash{
    flexWrap:wrap !important;
  }
  .margins{
    margin: 10px !important;
  }

}

@media (max-width: 740px){
  .image-size{
    width: 100% !important;
    height: 100% !important;
  }
  .filter-gradient{
    left: 20;
    top: 0;

  }
}

@media only screen and (device-width: 768px) {
  .features-image {
    width: 900px;
    height: 500px;
  }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) {
  .features-image {
    width: 700px;
    height: 400px;
  }
  .location-map{
    height: 200px;
    margin-top: 40px;
    text-align: center;
  }
  .navbar-right {
    float: right !important;
    margin-right: -15px;
    margin-bottom: 15px !important;
  }
  .pull-lefts{

    text-align: center !important;
  }
  .copyright {
    color: #777777;
    padding: 10px 15px;
    line-height: 20px;
    text-align: center;
    margin-left: 250px !important;
  }
  .parallax-image{
    width: 97% !important;
  }

}

@media only screen and (min-device-width: 800px) and (max-device-width: 1280px) {
  .location-map{
    height: 200px;
    margin-top: 40px;
    text-align: center;
  }
  .features-image {
    width: 700px;
    height: 400px;
  }
  .parallax-image{
    width: 99% !important;
  }
  .navbar-right {
    float: right !important;
    margin-right: -15px;
    margin-bottom: 15px !important;
  }
}

@media only screen and (min-device-width: 414px) and (max-device-width: 736px) {
  .pull-lefts{
    text-align: left !important;
  }
  .pull-rights{
    text-align: right !important;
    margin-top: 80px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px){
  .features-image {
    width: 500px;
    height: 400px;
  }
  .pull-rights{
    margin-top: -7px !important;

  }
}

@media only screen and (min-device-width: 414px) and (max-device-width: 736px){
  .pull-rights{
    margin-top: -7px !important;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px){
  .features-image {
    width: 700px !important;
    height: 400px;
  }
  .fit{
    right: 0 !important;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 667px){
  .features-image {
    width: 600px !important;
    height: 400px;
  }
}
@media only screen and (min-device-width: 800px) and (max-device-width: 1280px){
  .features-image {
    width: 1100px !important;
    height: 400px;
  }
  .herizontal{
    overflow-x: hidden !important;

  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px){
  .features-image {
    width: 900px;
    height: 400px;
  }
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  #my-content{
    display: block !important;
  }  /* show it on smaller screen */
  .navbar-header{
    background-color: white;
    border-color: white !important;
  }
  .navbar{
    border-color: white !important;
  }
}
@media screen and (min-width: 768px) {
  #my-content{ display: none !important; }   /* hide it on larger screens */
}
@media screen and (max-width: 990px) {
  #text{ display: none !important; }   /* hide it on larger screens */
}
@media screen and (min-width: 989px) {
  #texts{ display: none !important; }   /* hide it on larger screens */
}

@media screen and (max-width: 400px) {
  .logo-container .logo{
    width: 125px;
  }
  .fine{
    margin-top: 10px !important;
  }

}

@media screen and (max-width: 743px) {
  .parallax-image{
    padding:0;
    margin-top:70px !important;
  }

}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px){
  .margins{
    margin-bottom: 15px !important;
    margin-top: 0px;
  }
}
